import React from 'react'
import {logEvent} from '@lib/gtm'

import FacebookIcon from '@components/icons/Facebook'
import LinkedInIcon from '@components/icons/LinkedIn'
import YoutubeIcon from '@components/icons/Youtube'
import TikTokIcon from '@components/icons/TikTok'


export const SocialIcons = () => {
  const socials = [
    {
      label: 'Facebook',
      icon: <FacebookIcon />,
      url: 'https://www.facebook.com/datawowai/',
    },
    {
      label: 'LinkedIn',
      icon: <LinkedInIcon />,
      url: 'https://www.linkedin.com/company/datawowio/',
    },
    {
      label: 'Youtube',
      icon: <YoutubeIcon />,
      url: 'https://www.youtube.com/@datawowio/',
    },
    {
      label: 'TikTok',
      icon: <TikTokIcon />,
      url: 'https://www.tiktok.com/@datawow/',
    },
  ]
  return (
    <>
      {socials.map((social, index) => (
        <a
          className='header__nav__socials__links'
          href={social.url}
          target='_blank'
          rel='nofollow noopener noreferrer'
          onClick={() => {
            logEvent({
              ga: {
                category: 'Homepage',
                action: 'Click ' + social.label,
                label: 'Footer',
              },
            })
          }}
          key={index}
        >
          {social.icon}
        </a>
      ))}
    </>
  )
}