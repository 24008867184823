export const routes = [
  {
    label: 'บริการของเรา',
    key: 'services',
    href: '#',
    subMenus: [
      {
        label: 'AI Consultation',
        category: '-',
        icon: 'IconAIConsultation',
        href: '/ai-consultation',
      },
      {
        label: 'Custom AI Development',
        category: '-',
        icon: 'IconCustomAI',
        href: '/custom-ai',
      },
      {
        label: 'AI Readiness',
        category: '-',
        icon: 'IconAIReadiness',
        href: '/ai-readiness',
      },
      {
        label: 'Data Analytics',
        category: '-',
        icon: 'IconDataAnalytics',
        href: '/services/data-analytics',
      },
      {
        label: 'Data Labeling',
        category: '-',
        icon: 'IconDataLabeling',
        href: '/services/data-labeling',
      },
      {
        label: 'PDPA Core',
        category: '-',
        icon: 'IconPDPACore',
        href: '/products/pdpa-core',
      },
      {
        label: 'Accurately',
        category: 'ผลิตภัณฑ์',
        icon: 'IconAccurately',
        href: '/products/accurately',
      },
      {
        label: 'PDPA Suite',
        category: 'ผลิตภัณฑ์',
        icon: 'IconPDPASuite',
        href: 'https://pdpa.pro/our-products',
      },
    ],
  },
  {
    label: 'อุตสาหกรรม',
    key: 'industries',
    href: '#',
    subMenus: [
      {
        label: 'การผลิตและโลจิสติกส์',
        category: '-',
        icon: 'IconProduction',
        href: '/products/logistics',
      },
      {
        label: 'เครื่องจักร',
        category: '-',
        icon: 'IconMachinery',
        href: '/ai-consultation/machinery',
      },
      {
        label: 'ยานยนต์', 
        category: '-',
        icon: 'IconAutomotive',
        href: '/ai-consultation/automotive',
      },
      {
        label: 'สิ่งทอ',
        category: '-',
        icon: 'IconTextiles',
        href: '/ai-consultation/textile',
      },
      {
        label: 'อาหารและเครื่องดื่ม',
        category: '-',
        icon: 'IconFoodAndBeverage',
        href: '/ai-consultation/food',
      },
      {
        label: 'เกษตรกรรม',
        category: '-',
        icon: 'IconAgriculture',
        href: '/ai-consultation/agriculture',
      },
    ],
  },
  {
    label: 'ร่วมงานกับเรา',
    key: 'join',
    href: '/contact',
    subMenus: [
      {
        label: 'ร่วมงานกับเรา',
        icon: '',
        href: '/careers',
      },
    ],
  },
  {
    label: 'บทความ',
    key: 'blogs',
    href: '/blogs',
    subMenus: [
      {
        label: 'บทความ',
        icon: '',
        href: '/blogs',
      },
    ],
  },
]