import React from 'react'

const Facebook = () => {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='socials-icons'
    >
      <path
        d='M24.5273 12.7573C24.5273 6.1299 19.1548 0.757324 12.5273 0.757324C5.89992 0.757324 0.527344 6.1299 0.527344 12.7573C0.527344 18.7468 4.91555 23.7113 10.6523 24.6115V16.2261H7.60547V12.7573H10.6523V10.1136C10.6523 7.10607 12.4439 5.44482 15.1849 5.44482C16.4974 5.44482 17.8711 5.6792 17.8711 5.6792V8.63232H16.358C14.8673 8.63232 14.4023 9.5574 14.4023 10.5073V12.7573H17.7305L17.1984 16.2261H14.4023V24.6115C20.1391 23.7113 24.5273 18.7468 24.5273 12.7573Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Facebook
