import React from 'react'
import {useRouter} from 'next/router'
import classNames from 'classnames'
import {
  IconAIConsultation,
  IconCustomAI,
  IconAIReadiness,
  IconDataAnalytics,
  IconDataLabeling,
  IconPDPACore,
  IconAccurately,
  IconPDPASuite,
  IconProduction,
  IconMachinery,
  IconAutomotive,
  IconTextiles,
  IconFoodAndBeverage,
  IconAgriculture,
} from '@components/Icon'

import {MenuLink} from './MenuLink'

const icons = {
  IconAIConsultation: <IconAIConsultation />,
  IconCustomAI: <IconCustomAI />,
  IconAIReadiness: <IconAIReadiness />,
  IconDataAnalytics: <IconDataAnalytics />,
  IconDataLabeling: <IconDataLabeling />,
  IconPDPACore: <IconPDPACore />,
  IconAccurately: <IconAccurately />,
  IconPDPASuite: <IconPDPASuite />,
  IconProduction: <IconProduction />,
  IconMachinery: <IconMachinery />,
  IconAutomotive: <IconAutomotive />,
  IconTextiles: <IconTextiles />,
  IconFoodAndBeverage: <IconFoodAndBeverage />,
  IconAgriculture: <IconAgriculture />,
}

export const SubMenu = ({menus, isMobile, label}) => {
  const router = useRouter()
  const isCurrentPage = (href) => router.pathname == href
  const getCategories = (links) => {
    return links.reduce((acc, link) => {
      if (link.category && !acc.includes(link.category)) {
        acc.push(link.category)
      }
      return acc
    }, [])
  }

  return (
    <>
      {getCategories(menus).map((category, categoryIndex) => (
        <div key={categoryIndex}>
          <ul>
            <li>
              {category !== '-' || (category === '-' && !isMobile) ? (
                <h5
                  className={classNames({
                    header__menu__category: !isMobile && category !== '-',
                    header__menu__title: !isMobile && category === '-',
                    header__menu__mobile__category: isMobile,
                  })}
                >
                  {category !== '-' ? category : label}
                </h5>
              ) : (
                ''
              )}
            </li>
            {menus.map(
              (link) =>
                link.category === category && (
                  <li key={link.label}>
                    <MenuLink
                      href={link.href}
                      label={link.label}
                      icon={(!isMobile && icons[link.icon]) || null}
                      className={classNames({
                        header__menu__mobile__category__link:
                          isMobile && category !== '-',
                        'header__menu--active':
                          isMobile && isCurrentPage(link.href),
                        'header__menu--disabled': isMobile && link.href === '#',
                      })}
                    />
                  </li>
                )
            )}
          </ul>
        </div>
      ))}
    </>
  )
}
