import React from 'react'

const LinkedIn = () => {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.7508 0.693359H2.29922C1.31953 0.693359 0.527344 1.4668 0.527344 2.42305V22.959C0.527344 23.9152 1.31953 24.6934 2.29922 24.6934H22.7508C23.7305 24.6934 24.5273 23.9152 24.5273 22.9637V2.42305C24.5273 1.4668 23.7305 0.693359 22.7508 0.693359ZM7.64766 21.1449H4.08516V9.68867H7.64766V21.1449ZM5.86641 8.12773C4.72266 8.12773 3.79922 7.2043 3.79922 6.06523C3.79922 4.92617 4.72266 4.00273 5.86641 4.00273C7.00547 4.00273 7.92891 4.92617 7.92891 6.06523C7.92891 7.19961 7.00547 8.12773 5.86641 8.12773ZM20.9789 21.1449H17.4211V15.5762C17.4211 14.2496 17.3977 12.5387 15.5695 12.5387C13.718 12.5387 13.4367 13.9871 13.4367 15.4824V21.1449H9.88359V9.68867H13.2961V11.2543H13.343C13.8164 10.3543 14.9789 9.40273 16.7086 9.40273C20.3133 9.40273 20.9789 11.7746 20.9789 14.859V21.1449Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default LinkedIn
