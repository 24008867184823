import React from 'react'
import {motion} from 'framer-motion'
import classNames from 'classnames'
import {IconLineArrowUp} from '@components/Icon'
import {SubMenu, MenuLink, SocialIcons} from './index'
import Hamburger from '@components/Hamburger'

export const MobileMenu = ({
  isMobileMenuOpened,
  setIsMobileMenuOpened,
  routes,
  showMenu,
  setShowMenu,
}) => {
  return (
    <motion.div
      initial='hidden'
      animate={isMobileMenuOpened ? 'visible' : 'hidden'}
      variants={{
        hidden: {
          visibility: 'hidden',
          height: 0,
          opacity: 0,
        },
        visible: {
          visibility: 'visible',
          height: '100%',
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        },
      }}
      className='header__nav--mobile'
    >
      <div className='container'>
        <div className='header__nav--mobile__lang'>
          <Hamburger
            className='header__mobile--show header__toggle'
            isActive={isMobileMenuOpened}
            onClick={() => {
              setIsMobileMenuOpened(!isMobileMenuOpened)
            }}
          />
          <MenuLink href='https://datawow.io/' label='EN' />
        </div>
        <nav className='header__nav'>
          {routes.map((menu, index) => (
            <div className='header__nav__item' key={menu.key + index}>
              {menu.subMenus.length > 1 ? (
                <div>
                  <a
                    onClick={() => {
                      setShowMenu(showMenu === menu.key ? '' : menu.key)
                    }}
                    className={classNames({
                      'header__nav__item--opened': showMenu === menu.key,
                    })}
                  >
                    {menu.label}
                    <motion.span
                      variants={{
                        opened: {rotate: 0},
                        closed: {rotate: 180},
                      }}
                      initial={false}
                      animate={showMenu === menu.key ? 'opened' : 'closed'}
                      className={classNames('header__nav__item__icon')}
                    >
                      <IconLineArrowUp />
                    </motion.span>
                  </a>
                  <motion.div
                    className={classNames('header__menu__mobile')}
                    variants={{
                      hidden: {
                        visibility: 'hidden',
                        height: 0,
                      },
                      visible: {
                        visibility: 'visible',
                        height: 'auto',
                      },
                    }}
                    initial='hidden'
                    animate={showMenu === menu.key ? 'visible' : 'hidden'}
                  >
                    <SubMenu
                      menus={menu.subMenus}
                      label={menu.label}
                      isMobile={true}
                    />
                  </motion.div>
                </div>
              ) : (
                <MenuLink href={menu.subMenus[0].href} label={menu.label} />
              )}
            </div>
          ))}
          <div className='header__nav__item'>
            <MenuLink href='/contact' label='ติดต่อเรา' />
          </div>

          <div className='header__nav__socials'>
            <h5>ติดตามเรา</h5>
            <div className='header__nav__socials__container'>
              <SocialIcons />
            </div>
          </div>
        </nav>
      </div>
    </motion.div>
  )
}
