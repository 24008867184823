import React from 'react'
import {motion} from 'framer-motion'
import classNames from 'classnames'
import Link from 'next/link'
import {logEvent} from '@lib/gtm'
import {IconLineArrowUp, IconGlobe, IconTick} from '@components/Icon'
import {SubMenu, MenuLink} from './index'



export const DesktopMenu = ({routes, showMenu, setShowMenu, isCurrentPage}) => {
  return (
    <nav className='header__nav'>
      {routes.map((menu, index) => (
        <div className='header__nav__item header__mobile--hide' key={index}>
          {menu.subMenus.length > 1 ? (
            <div>
              <Link href={menu.href}>
                <a
                  onMouseOver={() => {
                    setShowMenu(menu.key)
                  }}
                  onMouseOut={() => {
                    setShowMenu('')
                  }}
                  className={classNames({
                    'header__menu--active': isCurrentPage(menu.href),
                    'header__menu--disabled': menu.href === '#',
                  })}
                >
                  {menu.label}
                  <motion.span
                    variants={{
                      opened: {rotate: 0},
                      closed: {rotate: 180},
                    }}
                    initial={false}
                    animate={showMenu === menu.key ? 'opened' : 'closed'}
                    className={classNames('header__nav__item__icon')}
                  >
                    <IconLineArrowUp />
                  </motion.span>
                  <motion.div
                    className={classNames('header__menu')}
                    variants={{
                        hidden: {
                          top: '70px',
                          visibility: 'hidden',
                          transitionProperty: 'top, visibility',
                          transitionDuration: '0.1s',
                          transitionDelay: '0s, 0s, 0.5s,',
                        },
                        visible: {
                          top: '60px',
                          visibility: 'visible',
                          transitionProperty: 'top, visibility',
                          transitionDuration: '0.1s',
                          transitionDelay: null,
                        },
                      }}
                    initial='hidden'
                    animate={showMenu === menu.key ? 'visible' : 'hidden'}
                  >
                    <SubMenu
                      menus={menu.subMenus}
                      label={menu.label}
                      isMobile={false}
                    />
                  </motion.div>
                </a>
              </Link>
            </div>
          ) : (
            <MenuLink href={menu.subMenus[0].href} label={menu.label} />
          )}
        </div>
      ))}
      <div className='header__nav__item'>
        {/* <div className='header__gradient-button'> */}
          <MenuLink
            href='/contact'
            label='ติดต่อเรา'
            isHeaderButton={true}
            className={{
              header__button: true,
            }}
          />
        {/* </div> */}
      </div>
      <div className='header__nav__item header__mobile--hide'>
        <div
          className='header__button--lang'
          onMouseOver={() => {
            setShowMenu('language')
          }}
          onMouseOut={() => {
            setShowMenu('')
          }}
        >
          TH
          <IconGlobe />
          <motion.div
            className={classNames('header__menu', 'header__menu--lang')}
            variants={{
                hidden: {
                  top: '70px',
                  visibility: 'hidden',
                  transitionProperty: 'top, visibility',
                  transitionDuration: '0.1s',
                  transitionDelay: '0s, 0s, 0.5s,',
                },
                visible: {
                  top: '60px',
                  visibility: 'visible',
                  transitionProperty: 'top, visibility',
                  transitionDuration: '0.1s',
                  transitionDelay: null,
                },
              }}
            initial='hidden'
            animate={showMenu === 'language' ? 'visible' : 'hidden'}
          >
            <ul>
              <li>
                <h5>Change Language</h5>
              </li>
              <li>
                <a
                  title='TH'
                  onClick={() => {
                    logEvent({
                      ga: {
                        category: 'Homepage',
                        action: 'Click Thai',
                        label: 'Header',
                      },
                    })
                  }}
                >
                  Thai
                  <IconTick />
                </a>
              </li>
              <li>
                <a
                  href='https://datawow.io/'
                  target='_blank'
                  rel='nofollow noopener noreferrer'
                  title='EN'
                  onClick={() => {
                    logEvent({
                      ga: {
                        category: 'Homepage',
                        action: 'Click English',
                        label: 'Header',
                      },
                    })
                  }}
                >
                  English
                </a>
              </li>
            </ul>
          </motion.div>
        </div>
      </div>
    </nav>
  )
}
