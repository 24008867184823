import React from 'react'
import {useRouter} from 'next/router'
import classNames from 'classnames'
import {logEvent} from '@lib/gtm'
import Link from 'next/link'

export const MenuLink = ({
  href,
  label = '',
  icon = null,
  hideLabel = false,
  className = {},
  isHeaderButton = false,
  ...props
}) => {
  const router = useRouter()
  const isCurrentPage = (href) => router.pathname == href
  const isExternalLink = href.startsWith('http')

  if (isExternalLink) {
    return (
      <a
        className={classNames({
          'header__menu--active': href !== '/' && isCurrentPage(href),
          'header__menu--disabled': href === '#',
          ...className,
        })}
        href={href}
        target={isExternalLink ? '_blank' : '_self'}
        rel='nofollow noopener noreferrer'
        title={label}
        onClick={() => {
          logEvent({
            ga: {
              category: 'Homepage',
              action: 'Click ' + label,
              label: 'Header',
            },
          })
          if (isExternalLink) {
            router.push(href)
          }
        }}
        {...props}
      >
        {icon && icon}
        {!hideLabel && label}
      </a>
    )
  }
  return (
    <Link href={href}>
      <a
        className={classNames({
          'header__menu--active': !isHeaderButton && href !== '/' && isCurrentPage(href),
          'header__menu--disabled': !isHeaderButton && href === '#',
          ...className
        })}
        onClick={() => {
          logEvent({
            ga: {
              category: 'Homepage',
              action: 'Click ' + label,
              label: 'Header',
            },
          })
        }}
        {...props}
      >
        {icon && icon}
        {!hideLabel && label}
      </a>
    </Link>
  )
}
