import React from 'react'

const Youtube = () => {
  return (
    <svg
      width='25'
      height='18'
      viewBox='0 0 25 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24.2883 3.95762C24.2883 3.95762 24.0539 2.30293 23.332 1.57637C22.418 0.620117 21.3961 0.61543 20.9273 0.55918C17.5711 0.31543 12.532 0.31543 12.532 0.31543H12.5227C12.5227 0.31543 7.48359 0.31543 4.12734 0.55918C3.65859 0.61543 2.63672 0.620117 1.72266 1.57637C1.00078 2.30293 0.771094 3.95762 0.771094 3.95762C0.771094 3.95762 0.527344 5.90293 0.527344 7.84355V9.6623C0.527344 11.6029 0.766406 13.5482 0.766406 13.5482C0.766406 13.5482 1.00078 15.2029 1.71797 15.9295C2.63203 16.8857 3.83203 16.8529 4.36641 16.9561C6.28828 17.1389 12.5273 17.1951 12.5273 17.1951C12.5273 17.1951 17.5711 17.1857 20.9273 16.9467C21.3961 16.8904 22.418 16.8857 23.332 15.9295C24.0539 15.2029 24.2883 13.5482 24.2883 13.5482C24.2883 13.5482 24.5273 11.6076 24.5273 9.6623V7.84355C24.5273 5.90293 24.2883 3.95762 24.2883 3.95762ZM10.0477 11.8701V5.1248L16.5305 8.50918L10.0477 11.8701Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Youtube
