import React, {useState, useEffect} from 'react'
import {useRouter} from 'next/router'
import classNames from 'classnames'
import {LogoDataWow} from '@components/Logo'
import Hamburger from '@components/Hamburger'
import {MenuLink, DesktopMenu, MobileMenu} from './header'

import {routes} from '@lib/routes'

const SiteHeader = (props) => {
  const {className = ''} = props
  const router = useRouter()

  const [showMenu, setShowMenu] = useState('')
  const [mounted, setMounted] = useState(false)

  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)

  const isCurrentPage = (href) => router.pathname == href

  useEffect(() => {
    if (isMobileMenuOpened) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isMobileMenuOpened])

  useEffect(() => {
    setMounted(true)
    return () => {
      setMounted(false)
    }
  }, [])

  return (
    <div className='header-wrapper'>
      <div className='container'>
        {mounted && (
          <header className={classNames('header', className)}>
            <div className='header__logo__container'>
              <Hamburger
                className='header__mobile--show header__toggle'
                isActive={isMobileMenuOpened}
                onClick={() => {
                  setIsMobileMenuOpened(!isMobileMenuOpened)
                }}
              />
              <MenuLink
                href='/'
                icon={
                  <LogoDataWow
                    className='header__logo'
                    width='256px'
                    height='40px'
                  />
                }
                label='Logo'
                hideLabel
              />
            </div>
            <DesktopMenu
              {...props}
              {...{showMenu, setShowMenu, routes, isCurrentPage}}
            />
            <MobileMenu
              {...props}
              {...{
                isMobileMenuOpened,
                setIsMobileMenuOpened,
                showMenu,
                setShowMenu,
                routes,
                isCurrentPage,
              }}
            />
          </header>
        )}
      </div>
    </div>
  )
}

export default SiteHeader
