import React from 'react'

const TikTok = () => {
  return (
    <svg
      width='22'
      height='25'
      viewBox='0 0 22 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.5999 0.757324H11.5552V17.1051C11.5552 19.053 9.99953 20.653 8.06361 20.653C6.12768 20.653 4.57203 19.053 4.57203 17.1051C4.57203 15.1921 6.09311 13.6269 7.95992 13.5573V9.45299C3.84606 9.52252 0.527344 12.8965 0.527344 17.1051C0.527344 21.3487 3.9152 24.7573 8.09819 24.7573C12.2811 24.7573 15.669 21.3139 15.669 17.1051V8.72252C17.1901 9.83559 19.0568 10.4965 21.0273 10.5313V6.42689C17.9852 6.32254 15.5999 3.81819 15.5999 0.757324Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default TikTok
